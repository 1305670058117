
import { Component, Vue } from 'vue-property-decorator'
import { BindList, WorkList, DeviceList } from '@/types/recruitUse'

@Component({
  filters: {
    filtersPresentStatus (value: string) {
      return value === '1' ? '在岗' : value === '2' ? '脱岗' : ''
    },
    filtersSosStatus (value: string) {
      return value === '1' ? '正常' : value === '2' ? '求助' : ''
    }
  }
})
export default class DetailList extends Vue {
  private isShowDialog = false
  private tableData = {
    loading: false,
    tr: [
      {
        label: '姓名',
        prop: 'workerName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '在岗监测',
        prop: 'dutyStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: 'SOS',
        prop: 'sosStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '数据上报时间',
        prop: 'collectTime',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private bindList: BindList[] = []
  private workList: WorkList[] = []
  private deviceList: DeviceList[] = []
  private isEdit: number | null = null

  private info = {
    projectId: this.projectId,
    sosStatus: '', // sos状态 0正常 1求助
    dutyStatus: '', // 在岗状态 1 在岗 2 脱岗
    workerName: '', // 工人姓名
    deviceNumber: ''
  }

  private workerDeviceId = ''// 设备绑定工人id

  private page = 1
  private size = 10
  private total = 0

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerInfoByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 轨迹回放
  onPlayback (id: string) {
    this.$router.push({
      name: 'recruitUseListPlayback',
      params: { workerId: id, projectId: this.projectId }
    })
  }

  // sos解除
  cancelSos (id: string) {
    this.$confirm('是否解除SOS报警?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.recruitUse.updateYhWorkerSos, {
        workerId: id
      }).then(() => {
        this.$message.success('解除成功')
        this.getData()
      })
    })
  }

  // 设备解绑
  onUntie (id: string) {
    this.$confirm('是否解除设备绑定?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.recruitUse.deleteYhWorkerInfo, {
        workerId: id
      }).then(() => {
        this.$message.success('解绑成功')
        this.getData()
      })
    })
  }

  // 设备绑定弹窗
  dialogShow () {
    this.isShowDialog = true
    this.getWorkList()
    this.getDeviceList()
  }

  // 工人下拉列表
  getWorkList () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerByList, {
      projectId: this.projectId
    }).then((res) => {
      this.workList = res || []
    })
  }

  // 设备下拉列表
  getDeviceList () {
    this.$axios.get(this.$apis.recruitUse.selectYhDeviceByList).then(res => {
      this.deviceList = res || []
    })
  }

  // 列表添加
  onAdd () {
    if (this.isEdit !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.bindList.push({
        workerId: '',
        deviceNumber: '',
        isInput: true
      })
      this.isEdit = this.bindList.length - 1
    }
  }

  // 保存当条
  onSave (row: BindList, index: number) {
    if (row.workerId === '') {
      this.$message.warning('请选择工人')
    } else if (row.deviceNumber === '') {
      this.$message.warning('请选择设备')
    } else {
      const deviceNumbers = this.deviceList.find((item: DeviceList) => row.deviceNumber === item.deviceNumber) as DeviceList
      const workerIds = this.workList.find((item: WorkList) => row.workerId === item.workerId) as WorkList
      if (deviceNumbers.workerId || workerIds.deviceNumber) {
        this.$confirm('设备或人员已被绑定，是否继续绑定？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.bindDevice(row, index)
        })
      } else {
        this.bindDevice(row, index)
      }
    }
  }

  // 设备绑定
  bindDevice (row: BindList, index: number) {
    this.$axios.post(this.$apis.recruitUse.insertYhWorkerInfo, {
      workerId: row.workerId,
      deviceNumber: row.deviceNumber
    }).then(() => {
      row.isInput = false
      this.$set(this.bindList, index, this.bindList[index]) // 重新渲染表格数据
      this.isEdit = null
      this.getWorkList()
      this.getDeviceList()
    })
  }

  getWorkName (workerId: string) {
    const item = this.workList.find((item: WorkList) => item.workerId === workerId) as WorkList
    return item.workerName
  }

  // 绑定设备弹窗关闭
  close () {
    this.isShowDialog = false
    this.bindList = []
    this.isEdit = null
    this.getData()
  }
}
